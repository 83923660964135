/*
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: tzk
 * @LastEditTime: 2023-02-14 19:55:49
 * @FilePath: /help.ringle.com/src/util/request.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import axios from 'axios'
import { Message } from 'element-ui'
import {getBrowser} from "@/util/index"

const service = axios.create({
//   baseURL: process.env.VUE_APP_GATEWAY_API,
  baseURL: process.env.VUE_APP_NEW_HELP_h5,
  timeout: 30000,
  headers: {
    'Content-Type': 'application/json'
  }
})

// request interceptor
service.interceptors.request.use(
  config => {
    const browser = getBrowser()
    const presto = navigator.userAgent.toLowerCase();
    let langArr = ['zh-CN','es-MX']
    let lang = navigator.language
    // let winUrl = window.location.href+'';
    // if(winUrl.indexOf('language=') > -1){
    //     let n = winUrl.indexOf('language=')
    //     lang = winUrl.substr(n+9)
    // }else{
        let AcceptLanguage = localStorage.getItem('Accept-Language')
        if(AcceptLanguage){
            lang = AcceptLanguage;
        }else{
            console.log(lang,langArr.includes(lang))
            if(!langArr.includes(lang)){
                lang = 'en'
            }
        }
        
    // }
   
    config.headers['Accept-Language'] = lang
    config.headers['Content-Type'] = 'application/json'
    config.headers['x-jaguar-device'] = 1005
    config.headers['x-jaguar-device-id'] = 'e6ba0cb4624fce7758e6d9942b15f000'
    config.headers['x-jaguar-device-name'] = browser
    config.headers['x-jaguar-device-os'] = presto
    config.headers['x-jaguar-business-type'] = 1000
    config.headers['x-jaguar-organizer-identity'] = 15
    return config
  },
  error => {
    Message.error(error)
    return Promise.reject(error)
  }
)

// response interceptor
service.interceptors.response.use(
  response => {
    //   console.log(service.config,response)
    if(response.config.url !== '/common/v1/guide/use'){
        const res = response.data 
        if(res.code=='200'){
            return res
        }else{
            Message.error(res.msg)
        }
    }else{
        return response.data
    }
  },
  error => {
    Message.error('服务器错误,请联系管理员')
    return Promise.reject(error)
  }
)

export default service
