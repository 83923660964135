/*
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: tzk
 * @LastEditTime: 2023-02-09 17:53:27
 * @FilePath: /help.ringle.com/src/api/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import request from '../util/request'
import requestAdmin from '../util/request_admin'


// 获取分类列表
export function getClassify(params) {
    return request({
      url: '/common/api/v2/_open/help/center/classify/list'+params,
      method: 'get',
    })
  }
  

// 获取问题列表
export function getQuestion(params) {
    return request({
      url: '/common/api/v2/_open/help/center/doc/list'+params,
      method: 'get',
    })
  }


// 搜索问题
export function getSearch(params) {
    return request({
      url: '/common/api/v2/_open/help/center/doc/search'+params,
      method: 'get',
    })
  }

//获取问题详情
export function getExternalh(params) {
    return request({
      url: '/common/api/v2/_open/help/center/doc/'+params,
      method: 'get',
    })
  }


//////////////////////////////////分隔线//////////////////////////////////////

//获取一级二级类型
export function getLevelType(params) {
  return request({
    url: '/common/v1/helpCenter/class/helpClasses',
    method: 'get',
    params:params
  })
}
//获取问题列表
export function getQueList(params) {
  return request({
    url: '/common/v1/helpCenter/doc/helpdocs',
    method: 'get',
    params:params
  })
}
//搜索
export function searchQue(params) {
  return request({
    url: '/common/v1/helpCenter/doc/seachHelpdocs',
    method: 'get',
    params:params
  })
}
//获取帮助中心列表
export function getGuideUseList(params) {
    return request({
      url: '/common/v1/guide/use/list',
      method: 'get',
      params:params
    })
  }
  
//获取问题详情
export function getQueDetail(params) {
  return request({
    url: '/common/v1/guide/use',
    method: 'get',
    params:params
  })
}
