/*
 * @Author: soofly wy463554101@163.com
 * @Date: 2022-07-17 21:12:53
 * @LastEditors: soofly wy463554101@163.com
 * @LastEditTime: 2022-07-27 10:53:06
 * @FilePath: /help.ringle.com/src/util/index.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
//非空判断
export function isEmpty(obj) {
    if (!obj || obj == "undefined" || obj == "null" || obj == "NaN") return false;
    else return true;
}


//获取浏览器名称
export function getBrowser() {
    var browser = {
            msie: false,
            firefox: false,
            opera: false,
            safari: false,
            chrome: false,
            netscape: false,
            appname: 'unknown',
            version: 0
        },
        ua = window.navigator.userAgent.toLowerCase();
    if (/(msie|firefox|opera|chrome|netscape)\D+(\d[\d.]*)/.test(ua)) {
        browser[RegExp.$1] = true;
        browser.appname = RegExp.$1;
        browser.version = RegExp.$2;
    } else if (/version\D+(\d[\d.]*).*safari/.test(ua)) {
        // safari
        browser.safari = true;
        browser.appname = 'safari';
        browser.version = RegExp.$2;
    }
    return browser.appname + '/' + browser.version;
}